import { StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import "../Logistics/Logistics.scss";
import ServiceToolbar from "../ServiceToolbar";

const TransitStorage = () => {
  return (
    <>
      <div className="logistics-con">
      <div>
        <p className="title">
        <StaticImage
          className="service-badge"
          src="../../../images/svg/warehouse.svg"
          width={45}
          quality={100}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="badge"
        />
        Transit Storage
        </p>
      </div>

        <div className="d-flex flex-row service-ch-land">
          <div className="logistics-left">
            <div className="logistics">
              <StaticImage
                  className="ch-img"
                  src="../../../images/transit-storage.png"
                  width={400}
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="about us"
                />

              <div className="text">
                {/* <p className="label">Stevedoring & Arrastre</p> */}
                <p>
                  Subic Grain's Services ensures that consignees have ample
                  storage space for their in-transit cargo. Thus, the Leyte 
                  Wharf facility can hold as much as 220,000 metric tonnes at any given
                  time in form of 15 silos (9,500 metric tonnes each) and Flat Warehouse
                  space (100,000 metric tonnes total).
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>

      <ServiceToolbar />
    </>
  );
};

export default TransitStorage;
